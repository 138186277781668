import {request} from './request';


// 三维建筑模型数据
export function get3dTiles(){
  return request({
    url:'/api/v1/gis/get3dtiles',
  })
}


// 倾斜摄影数据
export function getTiltphoto(){
  return request({
    url:'/api/v1/gis/gettiltphoto',
  })
}

