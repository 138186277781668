<template>
		
    <div id="cesiumContainer">
        
    </div>

</template>

<script>
import { MapInit, MapAddDom_Tianditu, MapAddDem, MapDebugCamera, MapFlyToDuration, MapAdd3DTiles, MapAddTiltPhoto } from './mapbase';

import { getMedia, getCode } from '../../network/media';
import { get3dTiles, getTiltphoto } from '../../network/gis';

export default {
    data () {
        return {
            viewer :'',
            tileset: '',
            
			gisSetting: {},
			gisDemSetting: {},
            gis3dTilesData: [],
            gisTiltphotoData: [],
        }
    },
    
    mounted (){
        
        this.init();

        
        this.viewer = MapInit('cesiumContainer');
        window.viewer = this.viewer;

        MapAddDom_Tianditu(this.viewer);
        // MapAddDem(this.viewer, "http://39.107.47.88:8015/fjsmart3d/3dtiles/dem/fujian/quansheng");
        this.initGisDem();


        // MapFlyToDuration(this.viewer, 1, 119.15310033018334, 25.438230557057278, 1076.635907030716, 357.9258445739128, -15.739569874715807, 359.9943962851459);
        // MapFlyToDuration(this.viewer, 1, 119.14905467550686, 25.45770995231256, 416.20519026141653, 0, -15, 0);
        MapFlyToDuration(this.viewer, 1, 119.15869429229328, 25.44699102323147, 338.2181324376124, 344.1356718633217, -7.178908830152411, 359.9589352292434);
        

        // cesium 左键点击事件
        var that = this;
        var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
        handler.setInputAction(function (movement) {
            var pick = viewer.scene.pick(movement.position);
            MapDebugCamera(that.viewer, movement);                // 摄像机信息
            
            // if (Cesium.defined(pick) && (pick.id.id === 'id')) {
            if (Cesium.defined(pick) ) {                // 点击实体 

            }

        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        

        // 三维建筑模型
        this.init3DTiles();
        
        // 加载白模
        // this.initBaiMo();



        // // 加载路网
        // this.viewer.dataSources.add(
        //     Cesium.GeoJsonDataSource.load("model/data/water1.geojson").then(function(e) {
        //         for (var t = e.entities.values, i = 0; i < t.length; i++) {
		// 	        var n = t[i];
		// 	        n.polyline ? (n.polyline.material = new Cesium.PolylineGlowMaterialProperty({
        //                 glowPower: .1,
        //                 color: new Cesium.Color(31 / 255, 44 / 255, 145 / 255, .9)
		// 	        }), n.polyline.width = 11, p.entities.add(n)) : r.drawWater(n)
		//         }
        //     })
        // );


        // 倾斜摄影
        // MapAddTiltPhoto(this.viewer, "http://47.117.2.79:6113/tiltphoto/jx/tileset.json", -17.0);
        // MapAddTiltPhoto(this.viewer, "http://127.0.0.1:6113/tiltphoto/jx/tileset.json", -17.0);

        // MapAddTiltPhoto(this.viewer, "http://127.0.0.1:8015/tiltphoto/1/tileset.json", -17.0);
        // MapAddTiltPhoto(this.viewer, "http://127.0.0.1:8015/tiltphoto/2/tileset.json", -17.0);

        // MapFlyToDuration(this.viewer, 1, 119.15814763292228, 26.1213197897056, 851.4677297329853, 0, -90, 0);      // 飞到闽侯荆溪





    },
    
    methods: {
        
        init() {
            var that = this;
                            
            var labeltabs = document.querySelectorAll(".nav ul li p")
            for (var i = 0; i<labeltabs.length; i++) {
                labeltabs[i].addEventListener('click', function() {
                    
                    for (var j = 0; j < labeltabs.length; j++) {
                        labeltabs[j].className = '';
                    }
                    this.className = 'currentqwys';

                    var index = this.getAttribute('index');  
                    switch(index) {
                        case "0":
                            that.showPOI(0, true, false);
                            break;
                        default:
                            that.showPOI(parseInt(index), true, true);
                            break;
                    }                        
                })
            }
        },

        initGisDem() {
            
			// Gis配置（16）
			getCode("gis_set", "16").then(res=>{
				this.gisDemSetting = res.data[0];					
                
                console.log(this.gisDemSetting.value1);
                // MapAddDem(this.viewer, "http://39.107.47.88:8015/fjsmart3d/3dtiles/dem/fujian/quansheng");
                MapAddDem(this.viewer, this.gisDemSetting.value1);
			})

        },

        // 添加三维建筑模型
        init3DTiles() {

			// Gis配置（15）
			getCode("gis_set", "15").then(res=>{
				this.gisSetting = res.data[0];					


                // // 三维模型
                // get3dTiles().then(res=>{
                //     this.gis3dTilesData = res.data;	

                //     for (var i=0; i<this.gis3dTilesData.length; i++) {
                //         if (this.gis3dTilesData[i].isshow == 0) {
                //             continue;
                //         }

                //         var url = this.gisSetting.value1 + '3dtiles/' + this.gis3dTilesData[i].name + '/tileset.json';

                //         var strarr_material = this.gis3dTilesData[i].arr_material.split(",");
                //         var arr_material = strarr_material.map((value)=>{
                //             return  parseFloat(value);
                //         })


                //         var strarr_transform = this.gis3dTilesData[i].arr_transform.split(",");
                //         var arr_transform = strarr_transform.map((value)=>{
                //             return  parseFloat(value);
                //         })

                //         MapAdd3DTiles(this.viewer, url, arr_transform, arr_material);
                //     }                                    
                // })


                // 倾斜摄影
                getTiltphoto().then(res=>{
                    this.gisTiltphotoData = res.data;	

                    for (var i=0; i<this.gisTiltphotoData.length; i++) {
                        if (this.gisTiltphotoData[i].isshow == 0) {
                            continue;
                        }

                        var url = this.gisSetting.value1 + 'tiltphoto/' + this.gisTiltphotoData[i].pathkey + '/tileset.json';
                        var heightoffset = this.gisTiltphotoData[i].heightoffset

                        console.log("getTiltphoto: " + i + "  " + url  + "  " + heightoffset)
                        
                        MapAddTiltPhoto(this.viewer, url, heightoffset);                        
                        // MapAddTiltPhoto(this.viewer, "http://127.0.0.1:8015/tiltphoto/2/tileset.json", -17.0);
                    }                                    
                })



			})
                

            // MapAdd3DTiles(this.viewer,
            //     "http://39.107.47.88:8015/fjsmart3d/3dtiles/新威厂房/tileset.json",
            //     Cesium.Matrix4.fromArray([-.6074430997801861, -.793302516596972, .04103654091598241, 0, -.23088881396756772, .2257524082002813, .9464281302753123, 0, -.7600679154615648, .5654263589121789, -.32029641979458245, 0, -22131.446078104433, 12125.55648208689, -33493.87142825313, 1]),
            //     new Cesium.Cartesian2(.55, 0)
            // )


            // MapAdd3DTiles(this.viewer,
            //     "http://39.107.47.88:8015/fjsmart3d/3dtiles/云度建模/tileset.json",
            //     Cesium.Matrix4.fromArray([-.6934169861245902, -.6091955593054299, -.38477740821991413, 0, -.5556123244000237, .11206659879140979, .8238543696613673, 0, -.4587677280810992, .7850616841738425, -.4161854438990353, 0, -10461.326191040222, 17727.130679511465, -31877.392038985156, 1]),
            //     new Cesium.Cartesian2(.55, 0)
            // )


            // let map_3dtiles = new Cesium.Cesium3DTileset({
            //     url: "http://39.107.47.88:8015/fjsmart3d/3dtiles/新威厂房/tileset.json",
            //     modelMatrix: Cesium.Matrix4.fromArray([.9674327727036074, -.03001955601920936, .25134171272476835, 0, .06786459427449287, .9873512875831842, -.14328932881337447, 0, -.24386108164889495, .15567999603108396, .95723325876862, 0, -4560.339690838475, 452.27799324318767, 5806.279022494797, 1]),
            //     imageBasedLightingFactor: new Cesium.Cartesian2(.55, 0)
            // });
            
            // this.viewer.scene.primitives.add(map_3dtiles);



        },

        

        // 加载白模
        initBaiMo() {

            let map_3dtiles = new Cesium.Cesium3DTileset({
                url: "model/baimo/tileset.json",
            });            
            this.viewer.scene.primitives.add(map_3dtiles);

        }
      
    }               

}



</script>


<style scoped>


</style>