<template>
  <h1></h1>

	<div class="panLback">		
    
	</div>



	<div class="panmap">
		<div class="mapleft"></div>
		<div class="maptop"></div>
		
		<div class="map">
    		<MapYQDL />
		</div>

		<div class="mapright"></div>
		<div class="mapbottom"></div>
	</div>

  
	<div class="panRback">		
    
	</div>


</template>

<script>
import MapYQDL from '@/components/map/MapYqdl.vue'


export default {

	components: {
		MapYQDL,
	
	},
 

	methods: {
		

	},

}
</script>

<style scoped>

.panmap {		
	top: 0px;
	left: 490px;
	width: 2860px;
	height: 945px;
	text-align: center;
  	position:relative;
  	overflow: hidden;
	
}


.panLback {	
	top: 0px;
	left: 0px;
	width: 500px;
	height: 945px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/kjcx/lback.png") no-repeat; 
}

.panRback {	
	top: 0px;
	left: 3340px;
	width: 500px;
	height: 945px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/kjcx/rback.png") no-repeat; 
}








</style>